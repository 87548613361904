import { ILogger } from '@cian/logger';

import { Component } from 'react';

import { TAgentRating } from 'shared/api/agent';
import { EmptyKpSearchContainer } from 'shared/common/components/PreInfiniteBanner/EmptyKpSearch/container';
import {
  EPreInfiniteBannerTypes,
  PreInfiniteBanner,
} from 'shared/common/components/PreInfiniteBanner/PreInfiniteBanner';
import { ICommercialTrackingOfferParams, ITrackingData, IUserGADataLayerData } from 'shared/common/state';
import { IJsonQuery } from 'shared/json_query';
import { IBreadcrumb, IOffer, ISerpDataQueristringToUri } from 'shared/offer/types';
import { EMaxAuctionService } from 'shared/repositories/search-offers.legacy/v2/search-offers-desktop.data';
import { IAbUseExperiment } from 'shared/types/abUseExperiments';
import { IButtonsAPI } from 'shared/types/buttons';
import { ICommentsAPI } from 'shared/types/comments';
import { TUser } from 'shared/types/user';

import { IHideOffer, IHidingOfferInfo } from '../../state/hide_offer';
import { EFeedbackComplaint, IComplaintFeedbackBody } from '../../state/offer_card/feedback_complaint';
import { AuctionStatusNotification } from '../AuctionStatusNotification';

import { IOfferCardCreatorProps, getOfferCardCreator, integrateAd } from './helpers';

import style from './index.css';

type TCommentsStateProps =
  | 'commentingBlockedOffers'
  | 'commentingBlockedAgents'
  | 'commentingErroneousOffers'
  | 'commentingErroneousAgents';

export interface IOffersStateProps extends Pick<ICommentsAPI, TCommentsStateProps> {
  queryString: string;
  offersQty: number;
  baseUrl: string;
  currentPageNumber: number;
  currentPath: string | undefined;
  currentSubdomain: string;
  isBot: boolean;
  isPopupsOpened(): boolean;
  isPrintEnabled: boolean;
  jsonQuery: IJsonQuery;
  logger: ILogger;
  offers: IOffer[];
  offersPerPage: number;
  qsToUris: ISerpDataQueristringToUri | null;
  ratingBlockedAgents: number[];
  shownId: number | undefined;
  suggestionDistancesSeoText: string | undefined;
  user: TUser;
  userGADataLayerData: IUserGADataLayerData | null;
  favoritesLimitForUnauthUser: number;
  showPushNotification?: boolean;
  hideOffer: IHideOffer;
  isNewAdFox?: boolean;
  breadcrumbs: IBreadcrumb[];
  mlRankingGuid: string | null;
  showEmptyKpSearch?: boolean;

  complaintsFormStatus: EFeedbackComplaint;

  /** Максимальная ставка аукциона на выдаче */
  maxAuctionBet: number;
  /** Максимальная услуга выдачи */
  maxAuctionService?: EMaxAuctionService;
  isLightView?: boolean;
  soprApi: string;
  deviceType: string;
  villagesLink?: string;
  showCountrysideTrap?: boolean;
  aggregatedOffersCount: number;
  extendedOffersCount: number;
  isOfferInSavedSearch?: boolean;
  isQaMode?: boolean;
  excludedUtilitiesTermsRegions: number[];
  isRedesignEnabled: boolean;
  trackingData: ITrackingData;
  auctionBanks?: number[];
  abUseExperiments: IAbUseExperiment[];
  isDealRentDisablePhonesExperimentActive?: boolean;
  villagePromotionLabel?: boolean | null;
  knAuctionCompanies: Array<number>;
}

export interface IOffersDispatchProps extends IButtonsAPI {
  closePopup(): void;
  onAgentRatingChanged(offer: IOffer, rating: TAgentRating): void;
  onComplaintSent(offerId: number, name: string, message?: string): void;
  onFavoriteChanged(offer: IOffer, isFavorite: boolean): void;
  onOfferCommentsChanged(offer: IOffer, commentOffer: string | undefined, commentAgent: string | undefined): void;
  onUserInfoPopupOpen(offerId: number): void;
  onUserInfoPopupClose(offerId: number): void;
  hideOfferAction(parameters: IHidingOfferInfo): void;
  sendComplaintFeedback(body: IComplaintFeedbackBody): void;
  statusChanger(status: EFeedbackComplaint): void;
  onAnalyticsButtonClick?(offerId: number, params: ICommercialTrackingOfferParams): void;
  addToComparison({ offerId }: { offerId: number }): void;
  deleteFromComparison({ offerId }: { offerId: number }): void;
}

export type TOffersProps = IOffersStateProps & IOffersDispatchProps;

export class Offers extends Component<TOffersProps, {}> {
  public render() {
    const {
      offers,
      offersPerPage,
      currentPageNumber,
      isPrintEnabled,
      offersQty,
      queryString,
      user,
      showPushNotification,
      hideOffer: { hiddenOffers, hiddenSuggestions },
      mlRankingGuid,
      showEmptyKpSearch,
      isLightView,
      soprApi,
      deviceType,
      villagesLink,
      showCountrysideTrap,
      aggregatedOffersCount,
      extendedOffersCount,
    } = this.props;

    const phone = user.isAuthenticated && user.phones && user.phones.find(phone => phone.confirmed);
    const email = user.isAuthenticated && user.email;

    const offerCardCreatorProps = {
      ...this.props,
      offersQty,
      queryString,
      defaultPhone: phone && phone.phone,
      defaultEmail: email,
      showPushNotification,
      isLightView,
      soprApi,
      deviceType,
    };

    const cardsWithAd = integrateAd({
      currentPageNumber,
      perPage: offersPerPage,
      offers,
      offerCardCreator: getOfferCardCreator({ props: offerCardCreatorProps as IOfferCardCreatorProps }),
      isPrintEnabled,
      suggestionFragment: { isSimilar: false },
      hiddenOffers,
      hiddenSuggestions,
      mlRankingGuid,
      villagesLink,
      showCountrysideTrap,
      extendedOffersOffset: aggregatedOffersCount - extendedOffersCount,
      keyPrefix: 'regular',
    });

    const emptyListingBanner = showEmptyKpSearch ? (
      <EmptyKpSearchContainer />
    ) : (
      <PreInfiniteBanner type={EPreInfiniteBannerTypes.empty} />
    );

    return (
      <div className={style['wrapper']}>
        {!offers.length && emptyListingBanner}
        <AuctionStatusNotification />
        {cardsWithAd}
      </div>
    );
  }
}
