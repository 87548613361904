/* eslint-disable react/no-danger, max-lines */

import { ErrorLogComponent } from '@cian/error-log-component';
import { Spinner } from '@cian/ui-kit';

import classNames from 'classnames';
import { Component, ErrorInfo } from 'react';

import { readAllOffersForSavedSearches } from 'shared/api/saved_searches/readAllOffers';
import { DisablePageScrollComponent } from 'shared/components/DisablePageScrollComponent';
import { UserProfilingModal } from 'shared/components/UserProfilingModal';
import { UserProfilingModalService } from 'shared/components/UserProfilingModal/internal/services/UserProfilingModalService';
import { BestPlaceBannerContainer } from 'shared/containers/BestPlaceBannerContainer';
import { CommercialSaveSearchModalContainer } from 'shared/containers/CommercialSaveSearchModalContainer';
import { CoworkingProfitBannerContainer } from 'shared/containers/CoworkingProfitBannerContainer';
import { FavoritesFoldersContainer } from 'shared/containers/FavoritesFoldersContainer';
import { FiltersContainer } from 'shared/containers/FiltersContainer';
import { MultiAdsBannerContainer } from 'shared/containers/MultiAdsBannerContainer';
import { ProfessionalSearchPromoContainer } from 'shared/containers/ProfessionalSearchPromoContainer';
import { SeoMarkedListContainer } from 'shared/containers/SeoMarkedListContainer';
import { DirectionsContainer } from 'shared/filters/components/directions/modal/container';
import { DistrictsContainer } from 'shared/filters/components/districts/container';
import LocationSwitherContainer from 'shared/filters/components/location_switcher/container';
import { MapContainer } from 'shared/filters/components/map/container';
import { FooterBanner } from 'shared/serp/components/AdfoxBanner';
import { Collections } from 'shared/serp/components/Collections/Collections';
import { FastLinksContainer } from 'shared/serp/components/fast_links/container';
import { HeaderContainer } from 'shared/serp/components/header/container';
import { HideOfferTopPopup } from 'shared/serp/components/hide_offer_top_popup';
import { OffersContainer } from 'shared/serp/components/offers/container';
import { OfficeCalculatorContainer } from 'shared/serp/components/office_calculator/container';
import { PaginationContainer } from 'shared/serp/components/pagination/container';
import { PromoteMobile } from 'shared/serp/components/promote_mobile';
import { SuggestionsContainer } from 'shared/serp/components/suggestions/container';
import { ComparisonNotificationLoadableContainer } from 'shared/serp/containers/ComparisonNotificationContainer';
import { QuickLinksContainer } from 'shared/serp/containers/QuickLinksContainer';
import { SeoBotCatcherLinksContainer } from 'shared/serp/containers/SeoBotCatcherLinksContainer';
import { SeoCrossLinksContainer } from 'shared/serp/containers/SeoCrossLinksContainer';
import { SummaryContainer } from 'shared/serp/containers/SummaryContainer';
import { EHideOfferState } from 'shared/serp/state/hide_offer';
import { isArrayWithItems } from 'shared/utils';
import { parseHash } from 'shared/utils/hash';
import { logComponentError } from 'shared/utils/log_component_error';
import { saveJsonQueryInLocalStorage } from 'shared/utils/save_json_query';

import { PreInfiniteBanner } from '../PreInfiniteBanner';
import { EPreInfiniteBannerTypes } from '../PreInfiniteBanner/PreInfiniteBanner';

import { ISearchEngineResultsState, SearchEngineResultsPageProps } from './types';

import * as style from './index.css';

const FLOCTORY_CHECK_TIMEOUT = 1500;

export class SearchEngineResultsPage extends Component<SearchEngineResultsPageProps, ISearchEngineResultsState> {
  public state = {
    isMounted: false,
  };

  /* istanbul ignore next */
  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    logComponentError({
      error,
      errorInfo,
      logger: this.props.logger,
    });
  }

  /* istanbul ignore next */
  public componentDidMount() {
    const { setKeyPushNotificationValue, setHideOfferState, httpApi, queryString, jsonQuery, logger } = this.props;

    saveJsonQueryInLocalStorage(jsonQuery);

    const checkFlocktoryLoad: Promise<IFlocktory> = new Promise(resolve => {
      const flocktoryAttempt = () => {
        if (window.flocktory && typeof window.flocktory.getSubscriptionStatus === 'function') {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          clearTimeout(timeIntervalFlocktoryCheck);

          return resolve(window.flocktory);
        }

        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        timeIntervalFlocktoryCheck = setTimeout(flocktoryAttempt, FLOCTORY_CHECK_TIMEOUT);
      };

      let timeIntervalFlocktoryCheck = setTimeout(flocktoryAttempt, FLOCTORY_CHECK_TIMEOUT);
    });

    checkFlocktoryLoad.then(flocktory => {
      flocktory.getSubscriptionStatus().then(response => {
        /**
         * Если flocktory вернули поле subscribed и оно равно false,
         * значит текущий пользователь не подписан на пуши.
         */
        if ('subscribed' in response && !response.subscribed) {
          setKeyPushNotificationValue(true);
        }
      });
    });

    /**
     * При уходе с выдачи по сохраненному поиску отметить все "новые" оферы просмотренными
     */
    window.addEventListener('beforeunload', () => {
      const searchParams = new URLSearchParams(queryString);
      if (searchParams.has('saved_search_id')) {
        const savedSearchId = Number(searchParams.get('saved_search_id'));
        readAllOffersForSavedSearches(httpApi, { savedSearchId }, logger);
      }
    });

    /* istanbul ignore next */
    window.addEventListener('popstate', async () => {
      this.props.setLoading();
      window.location.reload();
    });

    const parsedHash = parseHash(window.location.hash);
    if (Object.keys(parsedHash).includes('HIDE')) {
      setHideOfferState(parsedHash['HIDE'] as EHideOfferState);
      history.pushState('', document.title, window.location.pathname + window.location.search);
    }

    this.setState({ isMounted: true });

    /**
     * @todo Удалить блок с ветвлением если эксперимент не заапрувится
     * @description Данный функционал появился в задаче CD-116486, будет удалён в задаче CD-117944
     */
    if (!UserProfilingModalService.isUserProfilingModalWasShown) {
      this.props.onOpenUserProfilingModal();
    }
  }

  /* istanbul ignore next */
  public componentWillUnmount() {
    this.setState({ isMounted: false });
  }

  /* istanbul ignore next */
  public render() {
    const {
      deviceType,
      isFetching,
      isPrintEnabled,
      isPromotionMobileClosed,
      quickLinks,
      seo,
      aggregatedOffers,
      isTopPopupOpened,
      hideOfferState,
      hideOfferErrorMessage,
      isLightView,
      isOfferInSavedSearch,
      isPromoCollectionsEnabled,
      isRedesignEnabled,
      comparisonNotification,
      config,
      logger,
    } = this.props;

    const isQuickLinksAvailable =
      quickLinks &&
      isArrayWithItems(quickLinks.links) &&
      quickLinks.links.some(({ offersCount }) => Boolean(offersCount));

    /**
     * @todo Удалить переменную когда конференция закончится
     * @description Данный функционал появился в задаче CD-162829, будет удалён в задаче CD-162909
     */
    const isBestPlaceBannerEnabled = config.get<boolean>('Commercial.BestPlaceBanner.Enabled');

    return (
      <div className={classNames(style['serp'], { [style['serp--light']]: isLightView || isRedesignEnabled })}>
        {seo.text && <div className={style['seoText']} dangerouslySetInnerHTML={{ __html: seo.text }} />}
        <SeoMarkedListContainer />
        <ErrorLogComponent logger={logger}>
          <FiltersContainer />
        </ErrorLogComponent>
        <DisablePageScrollComponent isFetching={isFetching} />
        {isFetching && (
          <div className={style['preloadOverlay']}>
            <div className={style['preloader']}>
              <Spinner size={50} />
            </div>
          </div>
        )}
        {isQuickLinksAvailable && isLightView && (
          <ErrorLogComponent logger={logger}>
            <QuickLinksContainer />
          </ErrorLogComponent>
        )}

        {
          /**
           * @todo Удалить условие !isBestPlaceBannerEnabled когда конференция закончится
           * @description Данный функционал появился в задаче CD-162829, будет удалён в задаче CD-162909
           */
          !isBestPlaceBannerEnabled && (
            <ErrorLogComponent logger={logger}>
              <div className={style['multi-ads-banner-container']}>
                <MultiAdsBannerContainer />
              </div>
            </ErrorLogComponent>
          )
        }
        {
          /**
           * @todo Удалить условие !isBestPlaceBannerEnabled когда конференция закончится
           * @description Данный функционал появился в задаче CD-162829, будет удалён в задаче CD-162909
           */
          !isBestPlaceBannerEnabled && (
            <ErrorLogComponent logger={logger}>
              <div className={style['coworking-profit-banner-container']}>
                <CoworkingProfitBannerContainer containerClassName={style['coworking-profit-banner']} />
              </div>
            </ErrorLogComponent>
          )
        }
        {
          /**
           * @todo Удалить блок с баннером
           * @description Данный функционал появился в задаче CD-162829, будет удалён в задаче CD-162909
           */
          isBestPlaceBannerEnabled && (
            <ErrorLogComponent logger={logger}>
              <div className={style['best-place-banner-container']}>
                <BestPlaceBannerContainer />
              </div>
            </ErrorLogComponent>
          )
        }

        <ErrorLogComponent logger={logger}>
          <HeaderContainer />
        </ErrorLogComponent>

        {aggregatedOffers > 0 && (
          <ErrorLogComponent logger={logger}>
            <SummaryContainer />
          </ErrorLogComponent>
        )}
        {deviceType !== 'desktop' && !isPromotionMobileClosed && (
          <ErrorLogComponent logger={logger}>
            <PromoteMobile />
          </ErrorLogComponent>
        )}
        {isQuickLinksAvailable && !isLightView && (
          <ErrorLogComponent logger={logger}>
            <QuickLinksContainer />
          </ErrorLogComponent>
        )}
        <ErrorLogComponent logger={logger}>
          <ProfessionalSearchPromoContainer />
        </ErrorLogComponent>

        {isLightView && (
          <div className={style['delimiter']}>
            <hr />
          </div>
        )}
        {isPromoCollectionsEnabled && <Collections />}
        <ErrorLogComponent logger={logger}>
          <OffersContainer />
        </ErrorLogComponent>

        <ErrorLogComponent logger={logger}>
          {this.props.showPreInfinteSaveSearchBanner && (
            <PreInfiniteBanner
              isOfferInSavedSearch={isOfferInSavedSearch}
              type={EPreInfiniteBannerTypes.subscription}
            />
          )}
        </ErrorLogComponent>

        <ErrorLogComponent logger={logger}>
          <SuggestionsContainer />
        </ErrorLogComponent>

        <ErrorLogComponent logger={logger}>
          <PaginationContainer />
        </ErrorLogComponent>

        <div className={style['wrapper']}>
          <ErrorLogComponent logger={logger}>
            <SeoCrossLinksContainer />
          </ErrorLogComponent>
        </div>

        <ErrorLogComponent logger={logger}>
          <FastLinksContainer />
        </ErrorLogComponent>
        {!isPrintEnabled && (
          <ErrorLogComponent logger={logger}>
            <FooterBanner />
          </ErrorLogComponent>
        )}

        {this.props.isMapModalVisible && (
          <ErrorLogComponent logger={logger}>
            <MapContainer />
          </ErrorLogComponent>
        )}
        {this.props.isLocationSwitcherVisible && (
          <ErrorLogComponent logger={logger}>
            <LocationSwitherContainer />
          </ErrorLogComponent>
        )}
        {this.props.isDistrictModalVisible && (
          <ErrorLogComponent logger={logger}>
            <DistrictsContainer />
          </ErrorLogComponent>
        )}
        {this.props.isDirectionsModalVisible && (
          <ErrorLogComponent logger={logger}>
            <DirectionsContainer />
          </ErrorLogComponent>
        )}

        {this.props.isOfficeCalculatorVisible && (
          <ErrorLogComponent logger={logger}>
            <OfficeCalculatorContainer />
          </ErrorLogComponent>
        )}

        {this.state.isMounted && isTopPopupOpened && (
          <HideOfferTopPopup
            errorMessage={hideOfferErrorMessage}
            isError={hideOfferState === EHideOfferState.ERROR}
            lkUrl={this.props.lkUrl}
          />
        )}

        <FavoritesFoldersContainer />

        <ErrorLogComponent logger={logger}>
          <SeoBotCatcherLinksContainer />
        </ErrorLogComponent>

        {
          /**
           * @todo Удалить блок кода если эксперимент не заапрувится
           * @description Данный функционал появился в задаче CD-116486, будет удалён в задаче CD-117944
           */
          <ErrorLogComponent logger={logger}>
            <UserProfilingModal
              open={this.props.isUserProfilingModalVisible}
              onClose={() => this.handleUserProfilingModalClosed()}
            />
          </ErrorLogComponent>
        }
        {comparisonNotification && <ComparisonNotificationLoadableContainer notification={comparisonNotification} />}

        <ErrorLogComponent logger={logger}>
          <CommercialSaveSearchModalContainer />
        </ErrorLogComponent>
      </div>
    );
  }

  /**
   * @todo Удалить функцию если эксперимент не заапрувится
   * @description Данный функционал появился в задаче CD-116486, будет удалён в задаче CD-117944
   */
  private handleUserProfilingModalClosed() {
    this.props.onCloseUserProfilingModal();

    if (this.props.showUserProfilingModalOnceInSession) {
      UserProfilingModalService.setUserProfilingModalWasShown();
    }
  }
}
